<template>
	<v-container fluid>
		<ContactForm :dark="false" />
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('nav.contactUs'),
		}
	},
	components: {
		ContactForm: () => import('@/components/main/ContactForm.vue'),
	},
}
</script>
